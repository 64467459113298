import {
    API_BASE_URL,
    API_PROD_BASE_URL,
    WEB_DEV_BASE_URL,
    WEB_PROD_BASE_URL,
    CAMPAGNE_PROD_BASE_URL
} from "constants/ApiConstant";

const dev = {
    API_ENDPOINT_URL: API_BASE_URL + '/admin',
    PUBLIC_ENDPOINT_URL: API_BASE_URL + '/v1',
    ASSET_ENDPOINT_URL: API_BASE_URL + '/medias',
    WEB_URL: WEB_DEV_BASE_URL,
    CAMPAGNE_URL:WEB_DEV_BASE_URL,
    ENV: "DEV"
};

const prod = {
    API_ENDPOINT_URL: API_PROD_BASE_URL + '/admin',
    PUBLIC_ENDPOINT_URL: API_PROD_BASE_URL + '/v1',
    ASSET_ENDPOINT_URL: API_PROD_BASE_URL + '/medias',
    WEB_URL: WEB_PROD_BASE_URL,
    CAMPAGNE_URL:CAMPAGNE_PROD_BASE_URL,
    ENV: "PROD"
};

const test = {
    API_ENDPOINT_URL: API_BASE_URL + '/admin',
    PUBLIC_ENDPOINT_URL: API_BASE_URL + '/v1',
    ASSET_ENDPOINT_URL: API_BASE_URL + '/medias',
    WEB_URL: WEB_DEV_BASE_URL,
    CAMPAGNE_URL:WEB_DEV_BASE_URL,
    ENV: "TEST"
};

const getEnv = () => {
    switch (process.env.NODE_ENV) {
        case 'development':
            return dev
        case 'production':
            return prod
        case 'test':
            return test
        default:
            break;
    }
}

export const env = getEnv()
