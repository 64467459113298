import React from 'react'
import {AUTH_PREFIX_PATH, APP_PREFIX_PATH, EDITOR_PREFIX_PATH} from 'configs/AppConfig'

export const publicRoutes = [
    {
        key: 'login',
        path: `${AUTH_PREFIX_PATH}/login`,
        component: React.lazy(() => import('views/auth-views/authentication/login')),
    },
]

export const protectedRoutes = [
    {
        key: 'pe.list',
        path: `${APP_PREFIX_PATH}/parcours-evenement/list`,
        component: React.lazy(() => import('views/app-views/pe/list')),
        permission: []
    },
    {
        key: 'dashboard.default',
        path: `${APP_PREFIX_PATH}/dashboards/default`,
        component: React.lazy(() => import('views/app-views/dashboards/default')),
        permission: ["dashboards"]
    },
    {
        key: 'dashboard.previz',
        path: `${APP_PREFIX_PATH}/dashboards/previz`,
        component: React.lazy(() => import('views/app-views/dashboards/previz')),
        permission: ["dashboards"]
    },
    {
        key: 'fideles.list',
        path: `${APP_PREFIX_PATH}/fideles/list`,
        component: React.lazy(() => import('views/app-views/fideles/list')),
        permission: ["communaute"],
    },
    {
        key: 'horaires.list',
        path: `${APP_PREFIX_PATH}/horaires/list`,
        component: React.lazy(() => import('views/app-views/horaires/list')),
        permission: ["communaute"],
    },
    {
        key: 'events.list',
        path: `${APP_PREFIX_PATH}/events/list`,
        component: React.lazy(() => import('views/app-views/events/list')),
        permission: ["communaute"],
    },
    {
        key: 'galeries.list',
        path: `${APP_PREFIX_PATH}/galeries/list`,
        component: React.lazy(() => import('views/app-views/galeries/list')),
        permission: ["communaute"],
    },
    {
        key: 'galeries.images',
        path: `${APP_PREFIX_PATH}/galeries/images`,
        component: React.lazy(() => import('views/app-views/galeries/images')),
        permission: ["communaute"],
    },
    {
        key: 'promesses.list',
        path: `${APP_PREFIX_PATH}/promesses/list`,
        component: React.lazy(() => import('views/app-views/promesses/list')),
        permission: ["finance"],
    },
    {
        key: 'promesses.report',
        path: `${APP_PREFIX_PATH}/promesses/report`,
        component: React.lazy(() => import('views/app-views/promesses/report')),
        permission: ["finance"],
    },
    {
        key: 'cagnottes.list',
        path: `${APP_PREFIX_PATH}/cagnottes/list`,
        component: React.lazy(() => import('views/app-views/cagnottes/list')),
        permission: ["finance"],
    },
    {
        key: 'paiements.list',
        path: `${APP_PREFIX_PATH}/paiements/list`,
        component: React.lazy(() => import('views/app-views/paiements/list')),
        permission: ["comptabilite"],
    },
    {
        key: 'paiements.recurrent',
        path: `${APP_PREFIX_PATH}/paiements/recurrent`,
        component: React.lazy(() => import('views/app-views/paiements/list/recurrent')),
        permission: ["comptabilite"],
    },
    {
        key: 'paiements.valide',
        path: `${APP_PREFIX_PATH}/paiements/valide`,
        component: React.lazy(() => import('views/app-views/paiements/list/valide')),
        permission: ["comptabilite"],
    },
    {
        key: 'paiements.auto',
        path: `${APP_PREFIX_PATH}/paiements/auto`,
        component: React.lazy(() => import('views/app-views/auto_cerfa/list')),
        permission: ["comptabilite"],
    },
    {
        key: 'declaration.cerfa',
        path: `${APP_PREFIX_PATH}/declaration/cerfas`,
        component: React.lazy(() => import('views/app-views/declaration/cerfas/tabs')),
        permission: ["comptabilite"],
    },
    {
        key: 'services.groupe',
        path: `${APP_PREFIX_PATH}/services/group`,
        component: React.lazy(() => import('views/app-views/services_groupe/list')),
        permission: ["services"],
    },
    {
        key: 'services.list',
        path: `${APP_PREFIX_PATH}/services/list`,
        component: React.lazy(() => import('views/app-views/services/list')),
        permission: ["services"],
    },
    {
        key: 'abo-list',
        path: `${APP_PREFIX_PATH}/abonnements/list`,
        component: React.lazy(() => import('views/app-views/abonnements/list')),
        permission: ["services"],
    },
    {
        key: 'charges.list',
        path: `${APP_PREFIX_PATH}/charges/list`,
        component: React.lazy(() => import('views/app-views/operations/charges')),
        permission: ["gestion"],
    },
    {
        key: 'rentree.list',
        path: `${APP_PREFIX_PATH}/rentree/list`,
        component: React.lazy(() => import('views/app-views/operations/rentree')),
        permission: ["gestion"],
    },
    {
        key: 'intercomptes.list',
        path: `${APP_PREFIX_PATH}/intercomptes/list`,
        component: React.lazy(() => import('views/app-views/operations/inter')),
        permission: ["gestion"],
    },
    {
        key: 'comptes.all',
        path: `${APP_PREFIX_PATH}/comptes/all`,
        component: React.lazy(() => import('views/app-views/comptes/all')),
        permission: ["gestion"],
    },
    {
        key: 'comptes.list',
        path: `${APP_PREFIX_PATH}/comptes/list`,
        component: React.lazy(() => import('views/app-views/comptes/list')),
        permission: ["gestion"],
    },

    {
        key: 'livrets.list',
        path: `${APP_PREFIX_PATH}/livrets`,
        component: React.lazy(() => import('views/app-views/livrets/list')),
        permission: ["gestion"],
    },

    {
        key: 'infos.tabs',
        path: `${APP_PREFIX_PATH}/info/tabs`,
        component: React.lazy(() => import('views/app-views/infos/tabs')),
        admin: true
    },
    {
        key: 'preferences.tabs',
        path: `${APP_PREFIX_PATH}/preferences/tabs`,
        component: React.lazy(() => import('views/app-views/preferences/tabs')),
        admin: true
    },
    {
        key: 'bornes.tabs',
        path: `${APP_PREFIX_PATH}/bornes/tabs`,
        component: React.lazy(() => import('views/app-views/bornes/tabs')),
        admin: true
    },
    {
        key: 'virements.list',
        path: `${APP_PREFIX_PATH}/virements/list`,
        component: React.lazy(() => import('views/app-views/virements/list')),
        admin: true
    },
    {
        key: 'factures.list',
        path: `${APP_PREFIX_PATH}/factures/list`,
        component: React.lazy(() => import('views/app-views/factures/list')),
        admin: true
    },
    {
        key: 'access.list',
        path: `${APP_PREFIX_PATH}/access/list`,
        component: React.lazy(() => import('views/app-views/access/list')),
        admin: true
    },
    {
        key: 'actu.list',
        path: `${APP_PREFIX_PATH}/actu/list`,
        component: React.lazy(() => import('views/app-views/actualite/list')),
        permission: ["communaute"],
    },
    {
        key: 'contact.catalogue',
        path: `${APP_PREFIX_PATH}/contact/catalogue`,
        component: React.lazy(() => import('views/app-views/contact/catalogue')),
        permission: ["crm"],
    },
    {
        key: 'contact.famille',
        path: `${APP_PREFIX_PATH}/contact/famille`,
        component: React.lazy(() => import('views/app-views/contact/famille')),
        permission: ["crm"],
    },
    {
        key: 'contact.fiche',
        path: `${APP_PREFIX_PATH}/contact/fiche`,
        component: React.lazy(() => import('views/app-views/contact/page')),
        permission: ["crm"],
    },
    {
        key: 'contact.maps',
        path: `${APP_PREFIX_PATH}/contact/maps`,
        component: React.lazy(() => import('views/app-views/contact/maps')),
        permission: ["crm"],
    },
    {
        key: 'contact.forest',
        path: `${APP_PREFIX_PATH}/contact/forest`,
        component: React.lazy(() => import('views/app-views/contact/forest')),
        permission: ["crm"],
    },
    {
        key: 'rdv.list',
        path: `${APP_PREFIX_PATH}/rdv/list`,
        component: React.lazy(() => import('views/app-views/rdv/list')),
        permission: ["crm"],
    },
    {
        key: 'agenda',
        path: `${APP_PREFIX_PATH}/agenda`,
        component: React.lazy(() => import('views/app-views/agenda/list')),
        permission: ["crm"],
    },
    {
        key: 'message.list',
        path: `${APP_PREFIX_PATH}/messages/campagnes`,
        component: React.lazy(() => import('views/app-views/messages/list')),
        permission: ["crm"],
    },
    {
        key: 'message.detail',
        path: `${APP_PREFIX_PATH}/messages/detail`,
        component: React.lazy(() => import('views/app-views/messages/detail')),
        permission: ["crm"],
    },
    {
        key: 'rappel.list',
        path: `${APP_PREFIX_PATH}/rappel`,
        component: React.lazy(() => import('views/app-views/rappel/list')),
        permission: ["crm"],
    },
    {
        key: 'contact.search',
        path: `${APP_PREFIX_PATH}/contact/search`,
        component: React.lazy(() => import('views/app-views/contact/search')),
        permission: ["crm"],
    },
    {
        key: 'template.email',
        path: `${APP_PREFIX_PATH}/templates/email`,
        component: React.lazy(() => import('views/app-views/templates/email')),
        permission: ["crm"],
    },
    {
        key: 'bank.point',
        path: `${APP_PREFIX_PATH}/bank/point`,
        component: React.lazy(() => import('views/app-views/bank/point')),
        permission: ["gestion"],
    },

    {
        key: 'web.config',
        path: `${APP_PREFIX_PATH}/web/config`,
        component: React.lazy(() => import('views/app-views/web/config')),
        permission: ["web"],
    },
    {
        key: 'web.don',
        path: `${APP_PREFIX_PATH}/web/don`,
        component: React.lazy(() => import('views/app-views/web/don')),
        permission: ["web"],
    },
    {
        key: 'web.page',
        path: `${APP_PREFIX_PATH}/web/pages`,
        component: React.lazy(() => import('views/app-views/pages/list')),
        permission: ["web"],
    },
    {
        key: 'web.menu',
        path: `${APP_PREFIX_PATH}/web/menu`,
        component: React.lazy(() => import('views/app-views/web_menu')),
        permission: ["web"],
    },

    {
        key: 'form.submit',
        path: `${APP_PREFIX_PATH}/form/submit/`,
        component: React.lazy(() => import('views/app-views/form/submit')),
        permission: ["form"],
    },

    {
        key: 'form.simple',
        path: `${APP_PREFIX_PATH}/form/simple`,
        component: React.lazy(() => import('views/app-views/form/simple')),
        permission: ["form"],
    },

    {
        key: 'form.complex',
        path: `${APP_PREFIX_PATH}/form/complex`,
        component: React.lazy(() => import('views/app-views/form/complex')),
        permission: ["form"],
    },

    {
        key: 'form.calendar',
        path: `${APP_PREFIX_PATH}/form/calendar`,
        component: React.lazy(() => import('views/app-views/form/calendar/list')),
        permission: ["form"],
    },
    {
        key: 'form.calendar.slots',
        path: `${APP_PREFIX_PATH}/form/calendar/slots`,
        component: React.lazy(() => import('views/app-views/form/calendar/slots')),
        permission: ["form"],
    },

    {
        key: 'form.fete',
        path: `${APP_PREFIX_PATH}/form/fete`,
        component: React.lazy(() => import('views/app-views/form/fete')),
        permission: ["form"],
    },

    {
        key: 'campagne.list',
        path: `${APP_PREFIX_PATH}/campagne/list`,
        component: React.lazy(() => import('views/app-views/campagne/list')),
        permission: ["campagne"],
    },
    {
        key: 'campagne.stats',
        path: `${APP_PREFIX_PATH}/campagne/stats`,
        component: React.lazy(() => import('views/app-views/campagne/stats')),
        permission: ["campagne"],
    },
    {
        key: 'campagne.source',
        path: `${APP_PREFIX_PATH}/campagne/sources`,
        component: React.lazy(() => import('views/app-views/campagne/source')),
        permission: ["campagne"],
    },
    {
        key: 'campagne.team',
        path: `${APP_PREFIX_PATH}/campagne/teams`,
        component: React.lazy(() => import('views/app-views/campagne/teams')),
        permission: ["campagne"],
    },
    {
        key: 'campagne.settings',
        path: `${APP_PREFIX_PATH}/campagne/settings`,
        component: React.lazy(() => import('views/app-views/campagne/settings/tabs')),
        permission: ["campagne"],
    },
    {
        key: 'campagne.products',
        path: `${APP_PREFIX_PATH}/campagne/products`,
        component: React.lazy(() => import('views/app-views/campagne/products')),
        permission: ["campagne"],
    },
    {
        key: 'campagne.counters',
        path: `${APP_PREFIX_PATH}/campagne/counters`,
        component: React.lazy(() => import('views/app-views/campagne/counters')),
        permission: ["campagne"],
    },
    {
        key: 'campagne.dashboard',
        path: `${APP_PREFIX_PATH}/campagne/dashboard`,
        component: React.lazy(() => import('views/app-views/campagne/dashboard')),
        permission: ["campagne"],
    },
    {
        key: 'campagne.shortcut.paiements',
        path: `${APP_PREFIX_PATH}/campagne/paiements`,
        component: React.lazy(() => import('views/app-views/campagne/shortcut/paiements')),
        permission: ["campagne"],
    },
    {
        key: 'campagne.shortcut.abonnements',
        path: `${APP_PREFIX_PATH}/campagne/abonnements`,
        component: React.lazy(() => import('views/app-views/campagne/shortcut/abonnements')),
        permission: ["campagne"],
    },
    {
        key: 'campagne.gala.promesses',
        path: `${APP_PREFIX_PATH}/campagne/gala/promesses`,
        component: React.lazy(() => import('views/app-views/campagne/gala/index')),
        permission: ["campagne"],
    },
    {
        key: 'campagne.gala.presentateur',
        path: `${APP_PREFIX_PATH}/campagne/gala/presentateur`,
        component: React.lazy(() => import('views/app-views/campagne/gala/presentateur')),
    },


    //EDITORS
    {
        key: 'emailEditor',
        path: `${EDITOR_PREFIX_PATH}/email`,
        component: React.lazy(() => import('views/editors/EmailEditor')),
        permission: [],
    },
    {
        key: 'webEditor',
        path: `${EDITOR_PREFIX_PATH}/web`,
        component: React.lazy(() => import('views/editors/WebEditor/web')),
        permission: ["web"],
    },
    {
        key: 'sectionEditor',
        path: `${EDITOR_PREFIX_PATH}/section`,
        component: React.lazy(() => import('views/editors/WebEditor/section')),
        permission: ["campagne"],
    },
    {
        key: 'formEditor',
        path: `${EDITOR_PREFIX_PATH}/form`,
        component: React.lazy(() => import('views/editors/WebEditor/form/formContainer')),
        permission: ["form"],
    },

]
